import { navigate } from '@reach/router';
import { FormikValues, useFormik } from 'formik';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { t } from 'i18next';
import { RegistrationMappers } from 'mappers-to-service/registration.mappers';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from 'ui-kit-v2/button/button';
import DropdownComponent from 'ui-kit-v2/dropdown/dropdown';
import { toDropdownOption } from 'ui-kit-v2/dropdown/dropdown.props';
import { SpinnerV2 } from 'ui-kit-v2/spinner/spinner';
import TextInputComponent from 'ui-kit-v2/text-input/text-input';

import Spinner from 'ui-kit/spinner/spinner';
import ToastBox from 'ui-kit/toast-box/toast-box';

import WorkflowNavigationSection from 'display-components/workflow-navigation/workflow-navigation';

import { AddressVerificationAPIFailureModalContent } from 'components/add-address-form/AddAddressForm';
import {
    AddressSchema,
    AddressVerificationForm
} from 'components/address-verification-modal/AddressVerificationModal.component';
import BirdiModalErrorContent from 'components/birdi-modal/birdi-modal-error-content';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import LayoutWrapperComponent from 'components/layouts/workflow-v2/layout-wrapper/layout-wrapper';
import WorkflowLayout from 'components/layouts/workflow-v2/workflow.layout';

import AccountService from 'state/account/account.services';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    registrationContinueRegistrationRoutine,
    registrationMinimalFinalizeRoutine
} from 'state/registration/registration.routines';
import {
    registrationContinueRegistrationSelector,
    registrationIsLoadingSelector
} from 'state/registration/registration.selector';
import { RootState } from 'state/store';
import { AddressParts } from 'state/usps/usps.reducers';

import { BREAKPOINTS } from 'const/breakpoints';
import { getDaysOfMonthOptions, getYearOptions, monthOptionsNumberValue } from 'const/options';

import { PROFILE_SETUP_SCHEMA } from 'schema/profile-setup.schema';

import { scrollToField, submitFormik } from 'util/form.helper';
import { CustomError } from 'util/service.helper';
import { convertToTitleCase, formatPhoneNumber, removeChars } from 'util/string';
import { AddressValidateResponse } from 'util/usps';

import { useAddressVerification } from 'hooks/useAddressVerification';
import useWindowDimensions from 'hooks/useWindowDimensions';

import './profile-setup.styles.scss';

interface OnboardingProfileSetupPageProps {
    data: GatsbyTypes.OnboardingProfileSetupPage;
}

const OnboardingProfileSetupPage = ({ data }: OnboardingProfileSetupPageProps) => {
    const { addonBird } = data;
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const { verifyZip, getFullStateName, getShortStateName, verifyAddress, isGuamOrPuertoRico } =
        useAddressVerification();

    const { racesResult, gendersResult, ethnicitiesResult } = useSelector(
        (state: RootState) => state.membershipRegistrationReducer
    );

    const continueRegistrationResponseState = useSelector(registrationContinueRegistrationSelector);
    const isLoading = useSelector(registrationIsLoadingSelector);

    const [isZipValidating, setIsZipValidating] = useState(false);
    const [isPhoneValidating, setIsPhoneValidating] = useState(false);

    const minYear = new Date().getFullYear() - 120;
    const yearOptions = useMemo(() => getYearOptions({ min: minYear }), [minYear]);
    const dayOptions = useMemo(() => getDaysOfMonthOptions(), []);
    const monthOptions = useMemo(() => toDropdownOption(monthOptionsNumberValue ?? [], 'value', 'value', 'label'), []);
    const genderOptions = useMemo(
        () => toDropdownOption(gendersResult ?? [], 'genderCode', 'genderCode', 'genderDesc'),
        [gendersResult]
    );
    const racesOptions = useMemo(
        () => toDropdownOption(racesResult ?? [], 'raceId', 'raceId', 'raceDesc'),
        [racesResult]
    );
    const ethnicitiesOptions = useMemo(
        () => toDropdownOption(ethnicitiesResult ?? [], 'ethnicityId', 'ethnicityId', 'ethnicityDesc'),
        [ethnicitiesResult]
    );

    const initialValues = {
        dobDay: '',
        dobMonth: '',
        dobYear: '',
        gender: '',
        ethnicity: '',
        race: '',
        address: '',
        complement: '',
        zipCode: '',
        city: '',
        state: '',
        phoneNumber: '',
        providerName: '',
        isZipValid: false,
        isOver18: false
    };

    const validatePhoneNumber = async (phoneNumber: string): Promise<boolean> => {
        if (!phoneNumber) {
            return false;
        }
        try {
            const response = await AccountService.validatePhoneNumber().post([removeChars(phoneNumber)]);
            return response.phoneNumberVerifications[0].location?.toLowerCase() === 'united states';
        } catch {
            return false;
        }
    };

    const verifyAddressBeforeSend = (values: FormikValues) => {
        formik.setSubmitting(true);
        const address: AddressParts = {
            street1: values.address,
            street2: values.complement,
            city: values?.city?.trim(),
            state: getShortStateName(values?.state),
            zip: values?.zipCode,
            zip4: ''
        };

        verifyAddress({
            address,
            onSuccess: (validationResponse: AddressValidateResponse) => {
                if (validationResponse.responseCode === 'suggested') {
                    dispatch(
                        openModal({
                            showClose: false,
                            bodyContent: (
                                <AddressVerificationForm
                                    originalAddress={validationResponse.currentAddress!}
                                    correctedAddress={validationResponse.updatedAddress!}
                                    onSuccess={(selectedAddress) => handleSelectedAddress(selectedAddress, values)}
                                    onClose={handleCancelSelectAddress}
                                    className="membership-registration"
                                />
                            )
                        })
                    );
                }
            },
            onFailure: () => {
                formik.setSubmitting(false);
                formik.setFieldError('address', t('profileSetup.errors.address'));
                scrollToField('address');

                dispatch(
                    openModal({
                        showClose: true,
                        className: 'prescription-modal',
                        bodyContent: <AddressVerificationAPIFailureModalContent translation={t} />,
                        ctas: [
                            {
                                label: t('modals.healthConditions.submit'),
                                variant: 'primary',
                                onClick: () => {
                                    dispatch(closeModal({}));
                                },
                                dataGALocation: 'AddressVerificationError'
                            }
                        ]
                    })
                );
            }
        });
    };

    const verifyPhoneNumberBeforeSend = async (phoneNumber: string) => {
        const isValid = await validatePhoneNumber(phoneNumber);
        if (!isValid) {
            formik.setFieldError('phoneNumber', t('profileSetup.errors.phoneNumber'));
            formik.setSubmitting(false);
        }
        return isValid;
    };

    const handleSelectedAddress = (address: AddressSchema, values: FormikValues) => {
        formik.setFieldValue('address', address.address1);
        dispatch(closeModal({}));
        handleSubmit({
            ...values,
            address: address.address1,
            zipCode: address.zipcode,
            state: getShortStateName(address.state)
        });
    };

    const handleCancelSelectAddress = () => {
        dispatch(closeModal({}));
        formik.setSubmitting(false);
    };

    const handleRegistrationError = (error: CustomError) => {
        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'lg',
                onClose: () => {
                    dispatch(closeModal({}));
                },
                headerContent: <BirdiModalHeaderDanger headerText={t('profileSetup.title')} icon="alert" />,
                bodyContent: (
                    <BirdiModalErrorContent
                        description={error.message}
                        ctaText={t('profileSetup.buttons.gotIt')}
                        onClose={() => {
                            dispatch(closeModal({}));
                        }}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleRegistrationSuccess = () => {
        navigate('/secure/onboarding/health-profile');
    };

    const handleSubmit = (values: FormikValues) => {
        const registrationRequest = RegistrationMappers.continueRegistrationToMinimalFinalize(
            values,
            continueRegistrationResponseState.registration
        );

        dispatch(
            registrationMinimalFinalizeRoutine.trigger({
                registration: registrationRequest,
                onSuccess: () => handleRegistrationSuccess(),
                onFailure: (error: CustomError) => handleRegistrationError(error),
                onComplete: () => {
                    formik.setSubmitting(false);
                }
            })
        );
    };

    const verifyAndSubmitForm = async (values: FormikValues) => {
        const isPhoneValid = await verifyPhoneNumberBeforeSend(values.phoneNumber);
        if (!isPhoneValid) return;
        verifyAddressBeforeSend(values);
    };

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        enableReinitialize: true,
        validationSchema: PROFILE_SETUP_SCHEMA(t),
        onSubmit: verifyAndSubmitForm
    });

    const handleZipCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const zipCode = event.target.value;
        formik.setFieldValue('zipCode', zipCode);
        formik.setFieldValue('isZipValid', false);

        if (zipCode.length === 5) {
            handleVerifyZipCode(zipCode);
        }
    };

    const handleVerifyZipCode = (zipCode: string) => {
        formik.setFieldValue('city', '');
        formik.setFieldValue('state', '');
        formik.setFieldTouched('zipCode', true);
        setIsZipValidating(true);
        verifyZip({
            zip: zipCode,
            onSuccess: (validationResponse) => {
                formik.setFieldValue('city', convertToTitleCase(validationResponse?.address?.city) || '');
                formik.setFieldValue('state', getFullStateName(validationResponse?.address?.state || ''));
                formik.setFieldValue('isZipValid', true);
                setIsZipValidating(false);
            },
            onFailure: () => {
                formik.setFieldError('zipCode', 'Invalid Zip Code');
                formik.setFieldValue('isZipValid', false);
                setIsZipValidating(false);
            }
        });
    };

    const handlePhoneChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const formattedPhone = formatPhoneNumber(event.target.value, 'parentheses');
        formik.setFieldValue('phoneNumber', formattedPhone);
        formik.setTouched({ phoneNumber: true });
        if (event.target.value.length === 10) {
            setIsPhoneValidating(true);
            await verifyPhoneNumberBeforeSend(event.target.value);
            setIsPhoneValidating(false);
        }
    };

    useEffect(() => {
        dispatch(registrationContinueRegistrationRoutine.trigger());
    }, [dispatch]);

    return (
        <WorkflowLayout
            className="profile-setup-page"
            metaData={{ nodeTitle: 'Onboarding Profile Setup' }}
            isUnauthenticatedFlow={false}
        >
            <LayoutWrapperComponent
                title={t('profileSetup.title')}
                copyText={t('profileSetup.subtitle')}
                headerAlign="left"
                workflowNavigation={<WorkflowNavigationSection limit={5} usage={1} customColor="picton-blue" />}
            >
                <Spinner isVisible={isLoading} t={t} />
                <form className="profile-setup-page__container" onSubmit={formik.handleSubmit} noValidate>
                    <div className="profile-setup-page__container__dob">
                        <p>{t('profileSetup.dateOfBirth')}</p>
                        <div className="profile-setup-page__container__dob-row">
                            <DropdownComponent
                                {...formik.getFieldProps('dobMonth')}
                                label={t('profileSetup.form.dobMonth')}
                                onSelect={(value) => formik.setFieldValue('dobMonth', value)}
                                variant={width < BREAKPOINTS.sm ? 'default' : 'alt'}
                                options={monthOptions}
                                canSearch
                                error={(formik.touched.dobMonth && formik.errors.dobMonth) || !!formik.errors.isOver18}
                            />
                            <DropdownComponent
                                {...formik.getFieldProps('dobDay')}
                                label={t('profileSetup.form.dobDay')}
                                onSelect={(value) => formik.setFieldValue('dobDay', value)}
                                variant={width < BREAKPOINTS.sm ? 'default' : 'alt'}
                                options={dayOptions}
                                canSearch
                                error={(formik.touched.dobDay && formik.errors.dobDay) || !!formik.errors.isOver18}
                            />
                            <DropdownComponent
                                {...formik.getFieldProps('dobYear')}
                                label={t('profileSetup.form.dobYear')}
                                onSelect={(value) => formik.setFieldValue('dobYear', value)}
                                variant={width < BREAKPOINTS.sm ? 'default' : 'alt'}
                                options={yearOptions}
                                canSearch
                                error={(formik.touched.dobYear && formik.errors.dobYear) || !!formik.errors.isOver18}
                            />
                            <input type="hidden" {...formik.getFieldProps('isOver18')} />
                        </div>

                        {formik.errors.isOver18 && (
                            <ToastBox variant="error-v2" icon="error-v2">
                                {formik.errors.isOver18}
                            </ToastBox>
                        )}

                        <DropdownComponent
                            {...formik.getFieldProps('gender')}
                            label={t('profileSetup.form.gender')}
                            onSelect={(value) => formik.setFieldValue('gender', value)}
                            variant={width < BREAKPOINTS.sm ? 'default' : 'alt'}
                            options={genderOptions}
                            error={formik.touched.gender && formik.errors.gender}
                        />
                        <DropdownComponent
                            {...formik.getFieldProps('ethnicity')}
                            label={t('profileSetup.form.ethnicity')}
                            onSelect={(value) => formik.setFieldValue('ethnicity', value)}
                            variant={width < BREAKPOINTS.sm ? 'default' : 'alt'}
                            options={ethnicitiesOptions}
                        />
                        <DropdownComponent
                            {...formik.getFieldProps('race')}
                            onSelect={(value) => formik.setFieldValue('race', value)}
                            label={t('profileSetup.form.race')}
                            variant={width < BREAKPOINTS.sm ? 'default' : 'alt'}
                            options={racesOptions}
                        />
                    </div>

                    <div className="profile-setup-page__shipping-address">
                        <h2>{t('profileSetup.shippingAddress')}</h2>
                        <p>{t('profileSetup.shippingDescription')}</p>
                        <div className="profile-setup-page__shipping-address__form">
                            <GatsbyImage
                                className="profile-setup-page__shipping-address__form__image"
                                image={getImage(addonBird) as IGatsbyImageData}
                                alt={'bird-image'}
                            />
                            <TextInputComponent
                                {...formik.getFieldProps('address')}
                                label={t('profileSetup.form.address1')}
                                variant={width < BREAKPOINTS.sm ? 'default' : 'alt'}
                                error={formik.touched.address && formik.errors.address}
                            />
                            <TextInputComponent
                                {...formik.getFieldProps('complement')}
                                name="complement"
                                label={t('profileSetup.form.address2')}
                                variant={width < BREAKPOINTS.sm ? 'default' : 'alt'}
                            />

                            <div className="profile-setup-page__shipping-address__zipcode">
                                <TextInputComponent
                                    {...formik.getFieldProps('zipCode')}
                                    onChange={handleZipCodeChange}
                                    onlyNumbers
                                    maxLength={5}
                                    label={t('profileSetup.form.zipCode')}
                                    variant={width < BREAKPOINTS.sm ? 'default' : 'alt'}
                                    error={
                                        !isZipValidating &&
                                        formik.touched.zipCode &&
                                        (formik.errors.zipCode === t('profileSetup.errors.zipCode')
                                            ? t('profileSetup.errors.zipCode')
                                            : false || formik.errors.zipCode === t('profileSetup.errors.invalidZipCode')
                                            ? t('profileSetup.errors.invalidZipCode')
                                            : false ||
                                              formik.errors.zipCode === t('profileSetup.errors.isValidZipCode'))
                                    }
                                />
                                <input type="hidden" {...formik.getFieldProps('isZipValid')} />
                                <ButtonComponent
                                    variant="solid"
                                    color="neutral"
                                    size="lg"
                                    isLoading={isZipValidating}
                                    label={t('profileSetup.buttons.validate')}
                                    onClick={() => handleVerifyZipCode(formik.values.zipCode)}
                                    type="button"
                                />
                            </div>

                            {formik.errors.zipCode === t('profileSetup.errors.isValidZipCode') && (
                                <ToastBox variant="error-v2" icon="error-v2">
                                    {formik.errors.zipCode}
                                </ToastBox>
                            )}

                            {formik.values.city && formik.values.state && !formik.errors.zipCode && (
                                <div className="profile-setup-page__shipping-address__state_city">
                                    <TextInputComponent
                                        {...formik.getFieldProps('city')}
                                        label={t('profileSetup.form.city')}
                                        readOnly
                                        variant={width < BREAKPOINTS.sm ? 'default' : 'alt'}
                                    />
                                    <TextInputComponent
                                        {...formik.getFieldProps('state')}
                                        label={
                                            isGuamOrPuertoRico(formik.values.state)
                                                ? t('profileSetup.form.territory')
                                                : t('profileSetup.form.state')
                                        }
                                        readOnly
                                        variant={width < BREAKPOINTS.sm ? 'default' : 'alt'}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="profile-setup-page__single-input">
                        <h2>{t('profileSetup.phone')}</h2>
                        <p>{t('profileSetup.phoneInfo')}</p>
                        <TextInputComponent
                            {...formik.getFieldProps('phoneNumber')}
                            className="profile-setup-page__single-input__field"
                            label={t('profileSetup.form.phone')}
                            onlyNumbers
                            onChange={handlePhoneChange}
                            onBlur={handlePhoneChange}
                            maxLength={10}
                            variant={width < BREAKPOINTS.sm ? 'default' : 'alt'}
                            error={formik.touched.phoneNumber && formik.errors.phoneNumber}
                            disabled={isPhoneValidating}
                            contentRight={
                                isPhoneValidating
                                    ? {
                                          children: <SpinnerV2 />
                                      }
                                    : undefined
                            }
                        />
                    </div>

                    <div className="profile-setup-page__single-input">
                        <h2>{t('profileSetup.primaryCareProvider')}</h2>
                        <p>{t('profileSetup.primaryCareProviderDescription')}</p>
                        <TextInputComponent
                            {...formik.getFieldProps('providerName')}
                            className="profile-setup-page__single-input__field"
                            label={t('profileSetup.form.primaryCareProvider')}
                            variant={width < BREAKPOINTS.sm ? 'default' : 'alt'}
                        />
                    </div>
                    <ButtonComponent
                        isLoading={formik.isSubmitting}
                        disabled={formik.isValidating}
                        className="profile-setup-page__button_submit"
                        label={t('profileSetup.buttons.save')}
                        onClick={() => submitFormik(formik, ['dobMonth', 'dobDay'])}
                        full
                    />
                </form>
            </LayoutWrapperComponent>
        </WorkflowLayout>
    );
};

export default OnboardingProfileSetupPage;

export const query = graphql`
    query OnboardingProfileSetupPage($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        allBlockContentRegistrationFlowFooterLinks(
            filter: { field_registration_flow_types: { eq: "membership_flow" } }
        ) {
            edges {
                node {
                    field_registration_footer_link {
                        title
                        uri
                    }
                }
            }
        }
        addonBird: file(relativePath: { eq: "assets/images/get-started-flow-bird.png" }) {
            id
            childImageSharp {
                gatsbyImageData(
                    formats: PNG
                    layout: CONSTRAINED
                    transformOptions: { fit: COVER }
                    height: 56
                    width: 56
                )
            }
        }
    }
`;
