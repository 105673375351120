import { ThemeColors } from 'types/ui-kit';

import './spinner.styles.scss';

interface SpinnerV2Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
    size?: 'sm' | 'md' | 'lg';
    color?: ThemeColors;
    isVisible?: boolean;
    fullScreen?: boolean;
}

export const SpinnerV2 = ({ size = 'sm', color = 'cadet-blue', className, ...props }: SpinnerV2Props) => {
    return (
        <div
            className={`spinner-v2-component spinner-v2-component--${color} spinner-v2-component--${size} ${className}`}
            {...props}
        />
    );
};
