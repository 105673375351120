import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const PROFILE_SETUP_SCHEMA = (t: TFunction) =>
    Yup.object().shape({
        dobDay: Yup.string().required(t('profileSetup.errors.dob.day')),
        dobMonth: Yup.string().required(t('profileSetup.errors.dob.month')),
        dobYear: Yup.string().required(t('profileSetup.errors.dob.year')),
        gender: Yup.string().required(t('profileSetup.errors.gender')),
        ethnicity: Yup.string(),
        race: Yup.string(),
        address: Yup.string().required(t('profileSetup.errors.address')),
        complement: Yup.string(),
        zipCode: Yup.string()
            .required(t('profileSetup.errors.zipCode'))
            .matches(/^\d{5}$/, t('profileSetup.errors.invalidZipCode'))
            .test('zipCode-validation', t('profileSetup.errors.isValidZipCode'), function () {
                const { isZipValid } = this.parent;
                if (!isZipValid) {
                    return this.createError({ message: t('profileSetup.errors.isValidZipCode') });
                }
                return true;
            }),
        isZipValid: Yup.boolean().oneOf([true], t('profileSetup.errors.isValidZipCode')),
        city: Yup.string(),
        state: Yup.string(),
        phoneNumber: Yup.string()
            .required(t('profileSetup.errors.phoneNumber'))
            .min(10, t('profileSetup.errors.phoneNumber')),
        providerName: Yup.string(),
        isOver18: Yup.mixed().test('is-18', t('profileSetup.errors.dob.is18Over'), function () {
            const { dobDay, dobMonth, dobYear } = this.parent || {};

            if (!dobDay || !dobMonth || !dobYear) {
                return true;
            }

            const birthDate = new Date(Date.UTC(Number(dobYear), Number(dobMonth) - 1, Number(dobDay)));
            const today = new Date();

            const age = today.getUTCFullYear() - birthDate.getUTCFullYear();
            const monthDiff = today.getUTCMonth() - birthDate.getUTCMonth();

            if (monthDiff < 0 || (monthDiff === 0 && today.getUTCDate() < birthDate.getUTCDate())) {
                return age - 1 >= 18;
            }

            return age >= 18;
        })
    });
